@import "../../../styles/works";

.projects-main-container {
  @extend %main-container;

  .projects-header {
    @extend %header;
  }

  .projects-list-container {
    @extend %list-main-container;
    .skeletal-loading-main-container {
      border: 1px solid $skeleton-loading-backgroung-color;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 21rem;
      width: 100%;
      align-items: center;
      padding: 1.5rem;

      .skeletal-dummy-image {
        max-width: 280px;
        width: 100%;
        height: 250px;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeletal-dummy-title {
        width: 100%;
        height: 1.5rem;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeletal-dummy-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        span {
          display: inline-block;
          width: 100%;
          height: 1rem;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
    }
    .project-main {
      @extend %list-item;

      .project-title {
        @extend %list-item-title;
      }
      .project-description {
        @extend %list-item-description;
      }

      .project-admin-buttons {
        @extend %admin-buttons;
      }
    }
  }
}
