@import "../../../styles/variables";

.home-news-and-events-main-container {
  width: 100%;
  margin: 8rem auto 0rem auto;
  overflow: hidden;

  .home-news-and-events-header {
    display: flex;
    justify-content: center;
    h2 {
      @include header-styles(2rem, 0rem, 1.5rem, 0.7rem, 1.5rem, center);
    }
  }

  .home-news-and-events-container {
    margin: 2rem auto 0rem auto;
    width: 100%;
    display: flex;
    position: relative;
    height: auto;
    justify-content: center;

    .skeleton-loading-container {
      @extend %skeleton-loading-animation;
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-self: flex-start !important;

      .skeleton-header {
        width: 50rem;
        height: 2rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        animation: skeleton-loading 1s linear infinite alternate;
      }
      .skeleton-details-date {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        span {
          display: inline-block;
          width: 13.5rem;
          height: 2rem;
          border-radius: 5px;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }

      .skeleton-details {
        display: flex;
        gap: 3rem;
        .skeleton-dummy-image {
          width: 450px;
          height: 320px;
          border-radius: 5px;
          animation: skeleton-loading 1s linear infinite alternate;
        }
        .skeleton-dummy-description {
          display: flex;
          flex-direction: column;
          width: 68%;
          gap: 1rem;
          justify-content: center;
          span {
            display: inline-block;
            width: 100%;
            height: 1.7rem;
            border-radius: 5px;
            animation: skeleton-loading 1s linear infinite alternate;
          }
        }
      }
    }

    .home-events-container {
      .home-event-title {
        margin-bottom: 1rem;

        h3 {
          font-weight: 600;
          font-size: 1.3rem;
        }
      }

      .home-event-minor-details {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;

        p {
          display: inline-block;
          padding: 0.5rem 1rem;
          border-radius: 5px;

          &:first-child {
            background-color: $main-color;
            color: white;
            font-weight: 700;
          }

          &:last-child {
            background-color: $date-color;
            color: white;
            font-weight: 700;
          }
        }
      }
      .home-event-main-details {
        display: flex;
        gap: 3rem;

        .home-event-image {
          img {
            width: 450px;
            height: 320px;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .home-event-description {
          @include description-styles(50%);
        }

        .home-event-description a {
          text-decoration: none;
          color: $main-color;
          font-weight: 600;
        }
      }
    }
  }

  .event-details-image-slider-controls {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .dot {
      width: 20px;
      height: 20px;
      background-color: rgb(231, 231, 231);
      border-radius: 100%;
    }
    .active {
      width: 25px;
      height: 25px;
      background-color: $main-color;
    }

    .left,
    .right {
      font-size: 1.2rem;
      font-weight: 900;
      background-color: white;
      border: 2px solid black;
      color: black;
      padding: 0.5rem 0.8rem;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

//Responsiveness

@media (max-width: 1750px) {
  .home-events-container {
    width: 80% !important;
  }
}

@media (max-width: 1400px) {
  .home-news-and-events-main-container {
    .home-news-and-events-container {
      margin-top: 1rem;
      .home-event-title {
        text-align: center;
        line-height: 3rem;
      }

      .home-event-minor-details {
        justify-content: center;
        text-align: center;
      }
      .home-events-container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        .home-event-main-details {
          display: flex;
          flex-direction: column;
          align-items: center;

          .home-event-image {
            img {
              width: 400px;
              height: 250px;
            }
          }
        }
      }
    }

    .home-news-and-events-container {
      .skeleton-loading-container {
        align-items: center;
        .skeleton-header {
          max-width: 30rem;
          width: 100%;
        }
        .skeleton-details-date {
          width: 100%;
          justify-content: center;
        }
        .skeleton-details {
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          align-items: center;

          .skeleton-dummy-image {
            width: 300px;
            height: 200px;
          }

          .skeleton-dummy-description {
            width: 100% !important;
            span {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .home-news-and-events-main-container {
    margin-top: 5rem;
    .home-news-and-events-header {
      p {
        font-size: $small-screen-header-font-size;
        max-width: 18rem !important;
        width: 90% !important;
      }
    }

    .home-event-main-details {
      gap: 0.5rem !important;
      .home-event-image {
        width: 100% !important;
        img {
          width: 100% !important;
          object-fit: contain !important;
        }
      }
    }

    .home-event-description {
      font-size: $small-screen-description-font-size !important;
      line-height: $small-screen-line-height !important;
    }

    .event-details-image-slider-controls {
      .dot {
        width: 15px;
        height: 15px;
      }
      .active {
        width: 20px;
        height: 20px;
      }

      .left,
      .right {
        font-size: 1rem;
      }
    }

    .skeleton-dummy-image {
      width: 100% !important;
      height: 250px;
      object-fit: cover;
    }
  }
}
