@import "../styles/authentication";
// @import "../../../styles/variables";

.admin-login-page-main {
  @extend %main-page-content;

  .forgot-password-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    a {
      text-decoration: none;
      color: black;
      font-size: 0.8rem;
      font-weight: 600;

      &:hover {
        color: $main-color;
      }
    }
  }
  .admin-login-header {
    @extend %authentication-header;
  }
  .admin-login-form {
    @extend %authentication-form;
  }
}
