@import "../../../styles/variables";

.welcome-main {
  margin-top: 2rem;
  background-size: cover;
  height: calc(100vh - 5.5rem);
  max-height: 100rem;
  padding: 15rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.527);
    width: 100%;
    height: 100%;
  }

  .welcome-message {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;

    p {
      width: 70%;
      font-size: 3.5rem;
      color: white;
      font-weight: 700;
      text-align: center;
      line-height: 6rem;
    }
  }

  .welcome-page-read-more-button {
    margin-top: 3rem;
    z-index: 1;

    button {
      cursor: pointer;
      font-family: inherit;
      font-size: 1.4rem;
      font-weight: 600;
      color: white;
      background-color: $main-color;
      border: none;
      border-radius: 10px;
      padding: 0.8rem 2.7rem;
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .welcome-main {
    padding: 8rem 2rem;
    height: calc(100vh - 2rem);
    .welcome-message {
      p {
        width: 100%;
        font-size: 3rem;
      }
    }
    .welcome-page-read-more-button {
      button {
        font-size: 1.2rem !important;
      }
    }
  }
}

@media (max-width: 800px) {
  .welcome-main {
    .welcome-message {
      p {
        width: 100%;
        font-size: 2.8rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .welcome-main {
    padding: 2rem;
    height: calc(100vh - 3rem);
    margin-top: 0rem;

    .welcome-message {
      p {
        display: inline-block;
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }

    .welcome-page-read-more-button {
      button {
        font-size: 0.9rem !important;
        padding: 0.8rem 2rem;
      }
    }
  }
}

@media (max-width: 350px) {
  .welcome-main {
    .welcome-message {
      p {
        font-size: 7.5vw;
        line-height: 25vw;
      }
    }

    .welcome-page-read-more-button {
      button {
        font-size: 0.8rem !important;
        padding: 0.8rem 1.5rem;
      }
    }
  }
}

@media (max-height: 850px) {
  .welcome-main {
    height: fit-content;
  }
}
