.modal-main-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 20;
    background: #ffffff6a;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
}