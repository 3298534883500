@import "./variables";

.skeleton-loading-main {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 8rem auto 0 auto;

  .skeletal-header {
    width: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
    height: 1.5rem;
    border-radius: 5px;
  }

  .skeletal-image {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
    width: 100%;

    .skeletal-image-skeleton {
      max-width: 600px;
      width: 100%;
      height: 400px;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  .skeletal-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    span {
      display: inline-block;
      max-width: 30rem;
      height: 1.3rem;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  .skeletal-details-description {
    margin-top: 2rem;
    border-top: 1px solid rgb(201, 201, 201);

    .skeletal-title {
      margin-top: 2rem;
      display: inline-block;
      max-width: 20rem;
      width: 100%;
      height: 1.3rem;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .skeletal-description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;

      span {
        display: inline-block;
        width: 100%;
        height: 1.2rem;
        border-radius: 5px;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
  }
}

.project-details-main,
.publication-details-main {
  display: flex;
  flex-direction: column;
  margin: 8rem auto 0 auto;
  width: 90%;

  .project-details-header,
  .publication-details-header {
    width: 100%;

    h1 {
      text-align: left;
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 3rem;
    }
  }

  .project-details-image,
  .publication-details-image {
    display: flex;
    justify-content: center;
    margin: 7rem auto;

    img {
      max-width: 600px;
      height: auto;
      object-fit: cover;
      width: 100%;
    }
  }

  .extra-project-details,
  .extra-publication-details {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid gray;
    line-height: 2rem;

    .project-contributers,
    .publication-authors,
    .project-sponsors {
      .lab-contributers,
      .authors,
      .project-sponsor {
        &:hover {
          cursor: pointer;
          color: $main-color;
        }
      }
    }

    span {
      font-weight: 400;
    }
  }

  .project-details-description,
  .publication-details-abstract {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 0.7rem;
      border-bottom: 2px solid $main-color;
      width: 12rem;
      text-align: left;
      margin-bottom: 1rem;
    }

    p {
      line-height: 3rem;
      font-size: 1.1rem;
    }
  }

  .project-details-button,
  .publication-details-button,
  .publication-source-button {
    margin-top: 1rem;

    a {
      text-decoration: none;
      color: white;
    }

    button {
      background-color: $main-color;
      color: white;
      padding: 0.6rem 1.7rem;
      font-weight: 600;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      margin-top: 0.8rem;
      font-family: inherit;
      font-size: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .publication-source-button {
    margin-top: 0rem !important;
  }
}

//Responsiveness
@media (max-width: 500px) {
  .project-details-main,
  .publication-details-main {
    margin-top: 5rem;

    .project-details-image {
      margin: 3rem 0rem;
    }
    .project-details-header,
    .publication-details-header {
      font-size: $phone-screen-main-header-font-size !important;

      h1 {
        text-align: center;
      }
    }
    .project-details-description,
    .publication-details-abstract {
      align-items: center;

      h3 {
        padding: 0.7rem 2rem;
        width: fit-content;
      }

      p {
        text-align: justify !important;
        line-height: $small-screen-line-height !important;
      }
    }

    .project-details-button,
    .publication-details-button {
      display: flex;
      justify-content: center;
    }
  }
}
