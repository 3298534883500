@import "../../../styles/variables";

.skeleton-loading-main {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 8rem auto 0 auto;

  .skeletal-profile-main-details {
    display: flex;
    gap: 2rem;
    width: 100%;
    align-items: center;

    .skeletal-image-skeleton {
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .skeletal-profile-details {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 50%;
      span {
        display: inline-block;
        height: 1rem;
        animation: skeleton-loading 1s linear infinite alternate;
        max-width: 20rem;
        border-radius: 5px;
      }
    }
  }

  .skeletal-about-details {
    margin-top: 2rem;
    border-top: 1px solid rgb(201, 201, 201);

    .skeletal-title {
      margin-top: 2rem;
      display: inline-block;
      max-width: 10rem;
      width: 100%;
      height: 1.3rem;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .skeletal-description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;

      span {
        display: inline-block;
        width: 100%;
        height: 1.2rem;
        border-radius: 5px;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
  }
}

.lab-member-details-main-container {
  display: flex;
  flex-direction: column;
  margin: 8rem auto 0 auto;
  width: 90%;
  .lab-member-details-profile {
    display: flex;
    gap: 4rem;

    .lab-member-picture {
      img {
        width: 220px;
        height: 220px;
        border-radius: 220px;
        object-fit: cover;
      }
    }

    .lab-member-profile-details {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 1.2rem;
      font-weight: 500;

      span {
        &:first-child {
          font-weight: 600;
          font-size: 1.3rem;
        }
      }
    }
  }
  .lab-member-description {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    h3 {
      font-weight: 600;
      padding-bottom: 0.4rem;
      border-bottom: 2px solid $main-color;
      width: 5rem;
      text-align: left;
      margin-bottom: 0.5rem;
    }
    p {
      line-height: 3rem;
      font-size: 1.1rem;
    }
  }
  .lab-member-research-and-projects-container {
    margin-top: 2.5rem;

    .lab-member-research-and-projects-header {
      display: inline-block;
      font-weight: 600;
      padding-bottom: 0.6rem;
      border-bottom: 2px solid var(--main-color);
      width: 15rem;
      text-align: left;
      margin-bottom: 1rem;
    }

    .lab-member-research-and-projects {
      font-size: 1.1rem;
      margin-bottom: 2rem;

      .research-and-projects-title {
        padding-bottom: 0.2rem;
        font-weight: 600;
        line-height: 2;

        &:hover {
          color: $main-color;
          cursor: pointer;
        }
      }

      .research-and-projects-authors {
        font-weight: 500;
        line-height: 2;

        .research-and-projects-author {
          display: inline-block;
          &:hover {
            color: $main-color;
            cursor: pointer;
          }
        }
      }
    }

    .empty-research-and-projects {
      font-size: 1rem;
      font-weight: 600;
      color: $gray-color;
    }
  }
}

//Responsiveness
@media (max-width: 850px) {
  .lab-member-details-main-container {
    .lab-member-details-profile {
      flex-direction: column;
      align-items: center;
      text-align: center;
      .lab-member-picture {
        img {
          width: 10rem;
          height: 10rem;
        }
      }
    }

    .lab-member-description {
      align-items: center;

      h3 {
        text-align: center;
        padding: 0rem 2rem 0.5rem 2rem;
        width: 9rem;
      }
    }

    .lab-member-research-and-projects-container {
      display: flex;
      flex-direction: column;
      .lab-member-research-and-projects-header {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
}
@media (max-width: 500px) {
  .lab-member-details-main-container {
    .lab-member-profile-details {
      padding-top: 0rem !important;
    }
    .lab-member-description {
      h3 {
        font-size: 1.2rem !important;
      }

      p {
        line-height: $small-screen-line-height !important;
        text-align: justify;
      }
    }
  }
  .skeletal-profile-main-details {
    flex-direction: column;
    align-items: center;
    margin: auto;

    .skeletal-image-skeleton {
      width: 10rem !important;
      height: 10rem !important;
    }

    .skeletal-profile-details {
      width: 80% !important;
    }
  }
}
