@import "../../../styles/variables";

%form-inputs-styles {
  width: 80%;
  border: 1px solid $border-color;
  background-color: $input-background-color;
  border-radius: 5px;
  font-family: inherit;
  resize: none;
  font-size: 0.8rem;
}

%inputs-placeholder-styles {
  opacity: 1;
  color: $placeholder-color;
  font-weight: 500;
}

%navigation-buttons {
  padding: 0.7rem 2rem;
  border: 2px solid $main-color;
  border-radius: 5px;
  background-color: white;
  color: $main-color;
  white-space: nowrap;

  &:hover {
    background-color: $main-color;
    color: white;
  }
}

%inputs-card-main-container {
  max-width: 40rem;
  max-height: 55rem;
  width: 100%;
  height: 90%;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;

  %input-card-header {
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}

%image-upload-camera-button {
  position: absolute;
  bottom: 0;
  right: 8.8rem;

  img {
    width: 30px;
    height: 30px;
  }

  button {
    border-radius: 30px;
    padding: 0.5rem;
    border: 1px solid black;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $main-color;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.inputs-card-main-container {
  @extend %inputs-card-main-container;

  .inputs-card-header {
    @extend %input-card-header;
  }

  .inputs-card-form-inputs-container {
    position: relative;
    overflow-y: auto;
    height: 89%;
    max-height: 100%;

    .inputs-card-images-container {
      .inputs-card-image-main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;

        .inputs-card-image {
          border: 1px solid black;
          max-width: 20rem;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 1rem;
          border-radius: 10px;

          img {
            width: 270px;
            height: 200px;
          }
        }

        .input-camera-button {
          position: absolute;
          bottom: 0;
          right: 8.8rem;

          img {
            width: 30px;
            height: 30px;
          }

          button {
            border-radius: 30px;
            padding: 0.5rem;
            border: 1px solid black;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: $main-color;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }

    form {
      margin: 2rem auto 1rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      input {
        @extend %form-inputs-styles;
        height: 3rem;
        padding-left: 1.5rem;
        &::placeholder {
          @extend %inputs-placeholder-styles;
        }
      }
      textarea {
        @extend %form-inputs-styles;
        padding: 1rem 1rem 1rem 1.5rem;
        height: 20rem;
        &::placeholder {
          @extend %inputs-placeholder-styles;
        }
      }

      .add-button {
        margin-top: 0.5rem;
        padding: 0.5rem 2rem;
        background-color: $main-color;
        border-radius: 5px;
        border: 1px solid $main-color;
        color: white;
      }
    }
  }
}

//Select members dropdown
.select-fields {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .select-option-view {
    width: 100%;
    border: 1px solid $border-color;
    background-color: $input-background-color;
    border-radius: 5px;
    font-family: inherit;
    resize: none;
    font-size: 0.8rem;
    height: 3rem;
    display: flex;
    align-items: center;

    .select-option-placeholder {
      padding: 0rem 1.5rem;
      font-weight: 500;
      font-size: 0.8rem;
      color: $placeholder-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .item-list {
      padding: 0rem 0.5rem;
      display: flex;
      width: 90%;
      align-items: center;
      gap: 0.3rem;
      overflow-x: scroll;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      span {
        background-color: $main-color;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      .dropdown-icon {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 1rem;
      }
    }
  }

  .select-dropdown {
    position: absolute;
    top: 3.2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    max-height: 10rem;
    overflow-y: auto;
    background-color: white;
    z-index: 5;

    span {
      padding: 1rem;
      border-bottom: 1px solid black;
      font-weight: 600;
      font-size: 0.8rem;

      &:hover {
        background-color: $main-color;
        color: white;
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

//Delete and logout modal styles
%modal-container {
  border: 1px solid black;
  background-color: white;
  padding: 2rem;
  font-weight: 600;
  border-radius: 5px;
}

%modal-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;

  button {
    border-radius: 5px;
    padding: 0.4rem 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;

    &:first-child {
      background-color: $main-color;
    }
    &:last-child {
      background-color: rgb(20, 0, 0);
    }
  }
}
.delete-main-container {
  @extend %modal-container;

  .buttons {
    @extend %modal-buttons;
  }
}

//Logout modal
.logout-main-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  button {
    @extend %navigation-buttons;
  }
}

.logout-modal {
  @extend %modal-container;

  .logout-modal-buttons {
    @extend %modal-buttons;
  }
}

//Sponsor
.add-sponsor-main-container,
.edit-sponsor-main-container {
  max-height: 35rem;
  height: 90%;
  overflow-y: scroll;
  @extend %inputs-card-main-container;

  .add-sponsor-card-header,
  .edit-sponsor-card-header {
    @extend %input-card-header;
  }

   .inputs-card-images-container {
      .inputs-card-image-main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;

        .inputs-card-image {
          border: 1px solid black;
          max-width: 20rem;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 1rem;
          border-radius: 10px;

          img {
            width: 270px;
            height: 200px;
            object-fit: cover;
          }
        }

        .input-camera-button {
          position: absolute;
          bottom: 0;
          right: 8.8rem;

          img {
            width: 30px;
            height: 30px;
          }

          button {
            border-radius: 30px;
            padding: 0.5rem;
            border: 1px solid black;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: $main-color;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }

  .add-sponsor-card-form-inputs-container,
  .edit-sponsor-card-form-inputs-container {
    position: relative;
    padding: 1rem;
    width: 100%;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;

      input {
        @extend %form-inputs-styles;
        height: 3rem;
        padding-left: 1.5rem;
        &::placeholder {
          @extend %inputs-placeholder-styles;
        }
      }

      button {
        margin-top: 0.5rem;
        padding: 0.5rem 2rem;
        background-color: $main-color;
        border-radius: 5px;
        border: 1px solid $main-color;
        color: white;
      }
    }
  }

  .input-camera-button {
    @extend %image-upload-camera-button;
  }
}
