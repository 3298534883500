@import "../styles/variables";

.navigation-main-container {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 0.3rem;
  z-index: 15;

  .navigation-logo {
    padding-left: 3rem;
  }
  .navigation-main-container {
    display: flex;
    gap: 1rem;
    padding-right: 1rem;

    a {
      &.active {
        button {
          border-bottom: 2px solid $main-color;
          color: $main-color;
        }
      }
    }
  }

  button {
    background-color: white;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 0rem 0.7rem 0.4rem 0.7rem;
    font-weight: 600;
    font-family: inherit;

    &:hover {
      color: $main-color;
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .navigation-menu {
    position: fixed;
    top: 0rem;
    right: 0;
    z-index: 10;
    width: 50vw;
    height: 100vh;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;

    .close-container {
      padding-top: 2rem;

      button {
        background-color: $main-color;
        color: white;
        font-size: 1.3rem;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-weight: 600;
      }
    }

    .navigation-menu-buttons-container {
      width: 100%;
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 1rem;

      a {
        text-decoration: none;
        color: black;
        font-weight: 600;

        button {
          color: black;
        }

        span {
          display: inline-block;
          margin-top: 1rem;
          color: black;
          font-size: 1.5rem;
          padding-bottom: 1.5rem;
          width: 70%;
          max-width: 20rem;
          text-align: center;

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }

  .smaller-screen-nav-button {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    img {
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 1000px) {
  .navigation-menu {
    width: 80%;
  }
  .navigation-logo {
    padding-left: 0.7rem !important;
  }
}

@media (max-width: 600px) {
  .navigation-main-container {
    padding-bottom: 1rem;
    .smaller-screen-nav-button {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media (max-width: 535px) {
  .navigation-main-container {
    .navigation-logo {
      img {
        width: 110px;
        object-fit: contain;
      }
    }
    .navigation-menu {
      width: 100vw;
      // overflow-y: scroll;
    }
  }
}

@media (max-width: 350px) {
  .navigation-menu-buttons-container {
    margin-top: 3rem;

    a {
      span {
        font-size: 1rem;
      }
    }
  }
}
