@import "../../../styles/variables";

.events-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  .events-main-header {
    h1 {
      @include header-styles(3rem, 0rem, 2.3rem, 0.7rem, 2.3rem, center);
    }
  }

  .events-container {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto 0rem auto;
    width: 96%;

    .skeletal-loading-main-container {
      border: 1px solid $skeleton-loading-backgroung-color;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 21rem;
      width: 100%;
      align-items: center;
      padding: 1.5rem;

      .skeletal-dummy-image {
        max-width: 280px;
        width: 100%;
        height: 250px;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeletal-dummy-title {
        width: 100%;
        height: 1.5rem;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeletal-dummy-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        span {
          display: inline-block;
          width: 100%;
          height: 1rem;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
    }

    .event {
      border: 1px solid black;
      width: 21rem;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease-in-out;
      line-height: 1.8rem;

      &:hover {
        transform: scale(1.02);
      }

      img {
        border-radius: 0px;
        width: 100%;
        height: 220px;
        object-fit: cover;
      }

      .event-info {
        justify-self: start;
        padding: 1rem 1.5rem 0.5rem 1.2rem;

        span {
          display: inline-block;
          padding: 0.1rem 1.5rem;
          border-radius: 5px;
          font-size: 0.8rem;

          &:first-child {
            background-color: $main-color;
            color: white;
            font-weight: 600;
          }

          &:last-child {
            margin-left: 1rem;
            background-color: $date-color;
            color: white;
            font-weight: 600;
          }
        }
      }
      .event-header {
        font-weight: 600;
        padding: 0rem 1.5rem 0.5rem 1.2rem;
      }
      .event-description {
        padding-bottom: 1rem;
        padding: 0rem 1.5rem 0.5rem 1.2rem;

        a {
          color: $main-color;
          font-weight: 600;
          text-decoration: none;
        }
      }

      .add-event-button {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;
        gap: 1rem;

        button {
          background-color: $main-color;
          color: white;
          padding: 0.6rem 1.7rem;
          font-weight: 600;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          margin-top: 0.8rem;
          font-family: inherit;
          font-size: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.add-event-button button {
  background-color: var(--main-color);
  color: white;
  padding: 0.6rem 1.7rem;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 0.8rem;
  font-family: inherit;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Responsiveness
@media (max-width: 1200px) {
  .events-main-container {
    .events-main-header {
      h1 {
        font-size: $tablet-screen-main-header-font-size;
      }
    }
  }
}

@media (max-width: 500px) {
  .events-main-container {
    .events-main-header {
      width: 80%;
      h1 {
        font-size: $phone-screen-main-header-font-size;
      }
    }

    .event-info {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      span {
        margin-left: 0rem !important;
      }
    }
  }
}
