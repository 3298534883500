$main-color: #d90429;
$gray-color: #5d5d5d;
$date-color: #8d99ae;
$border-color: #a0a0a0;
$input-background-color: #f8f8f8;
$placeholder-color: #c7c7c7;
$valid-input-color: #04d939;
$small-screen-header-font-size: 1.5rem;
$small-screen-description-font-size: 1rem;
$tablet-screen-main-header-font-size: 2.5rem;
$phone-screen-main-header-font-size: 8vw;
$small-screen-line-height: 2.5rem;
$skeleton-loading-backgroung-color: #e9e9e9;

@mixin header-styles(
  $font-size,
  $padding-top,
  $padding-right,
  $padding-bottom,
  $padding-left,
  $text-align
) {
  font-size: $font-size;
  font-weight: 600;
  padding: $padding-top $padding-right $padding-bottom $padding-left;
  border-bottom: 3px solid $main-color;
  text-align: $text-align;
  margin-bottom: 1rem;
}

@mixin description-styles($width-size) {
  font-size: 1.2rem;
  line-height: 3rem;
  width: $width-size;
}

//Skeletal loading animation
%skeleton-loading-animation {
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 0%, 95%);
    }
    100% {
      background-color: $skeleton-loading-backgroung-color;
    }
  }
}

//button styles
%button-styles {
  background-color: $main-color;
  color: white;
  padding: 0.6rem 1.7rem;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 0.8rem;
  font-family: inherit;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
