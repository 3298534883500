@import "../../../styles/variables";

.contact-us-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  .contact-us-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      display: inline-block;
      font-size: 3rem;
      font-weight: 600;
      padding: 0rem 2.3rem 0.7rem 2.3rem;
      border-bottom: 3px solid $main-color;
      text-align: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.4rem;
      width: 80%;
      line-height: 2.4rem;
      text-align: center;
      font-weight: 500;
    }
  }

  .contact-us-location {
    display: flex;
    justify-content: center;

    .contact-us-location-description {
      margin-top: 2rem;
      border: 2px solid $main-color;
      max-width: 30rem;
      width: 90%;
      height: auto;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 2rem;

      .contact-us-location-header {
        font-size: 1.2rem;
        font-weight: 600;
        border-bottom: 2px solid $main-color;
        padding-bottom: 0.5rem;
        width: 12rem;
        margin-bottom: 1rem;
      }

      .contact-us-address {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        p {
          &:first-child {
            font-weight: 600;
          }

          &:nth-child(2) {
            line-height: 2rem;
          }
        }
      }

      .contact-us-email {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        margin-top: 1rem;
        p {
          &:first-child {
            font-weight: 600;
          }

          &:nth-child(2) {
            line-height: 1.5rem;
          }
        }
      }
      .contact-us-telephone {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        margin-top: 1rem;
        p {
          &:first-child {
            font-weight: 600;
          }

          &:nth-child(2) {
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .contact-us-form {
    margin-top: 5rem;
    width: 45%;
    display: flex;
    flex-direction: column;

    form {
      width: 100%;

      input {
        border: 1px solid #a0a0a0;
        background-color: #f8f8f8;
        border-radius: 5px;
        font-family: inherit;
        padding-left: 1rem;

        &:focus {
          border: none;
          outline: 2px solid $main-color;
          background-color: white;
        }
      }

      .name-inputs,
      .message-info-inputs {
        display: flex;
        gap: 1rem;

        input {
          width: 90%;
          height: 2.5rem;
        }
      }
      .message-info-inputs {
        margin-top: 1rem;
      }

      textarea {
        border: 1px solid #a0a0a0;
        background-color: #f8f8f8;
        border-radius: 5px;
        font-family: inherit;
        padding-left: 1rem;
        padding-top: 0.8rem;
        width: 100%;
        margin-top: 1rem;
        height: 15rem;
        resize: none;
        font-size: 0.8rem;

        &::placeholder {
          font-size: 0.85rem;
        }

        &:focus {
          border: none;
          outline: 2px solid $main-color;
          background-color: white;
        }
      }

      button {
        background-color: $main-color;
        color: white;
        padding: 0.6rem 4rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .text-inputs-header-and-message {
      justify-self: self-start;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      align-items: baseline;
      margin-bottom: 1rem;

      .send-message-container {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 600;
        border-bottom: 3px solid $main-color;
        padding: 0rem 1.5rem 0.7rem 0rem;
        width: 16rem;
        align-self: baseline;
      }

      .notification-message {
        border-radius: 5px;
        padding: 1rem;
        font-size: 0.9rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 1rem;
        line-height: 1.5rem;
        text-align: center;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}

.contact-us-form button {
  background-color: $main-color;
  color: white;
  padding: 0.6rem 4rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Responsiveness
@media (max-width: 900px) {
  .contact-us-form {
    width: 80% !important;
    form {
      width: 100%;
    }
  }
}
@media (max-width: 800px) {
  .contact-us-main-container {
    .contact-us-header {
      h1 {
        font-size: $tablet-screen-main-header-font-size;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: 650px) {
  .contact-us-form {
    span {
      width: 70% !important;
      font-size: 1.5rem !important;
      text-align: center;
      margin: auto;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name-inputs {
        flex-direction: column;
        width: 100% !important;
        input {
          width: 100% !important;
        }
      }
      .message-info-inputs {
        flex-direction: column;
        width: 100%;
        align-items: normal;
        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .contact-us-header {
    width: fit-content;
    h1 {
      font-size: $phone-screen-main-header-font-size !important;
    }
  }
}
