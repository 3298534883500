@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "./styles/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
}

.empty-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    font-size: 1.5rem;
    color: $border-color;
    font-weight: 600;
  }
}

@media (max-width: 800px) {
  .empty-message {
    p {
      font-size: 1.1rem;
    }
  }
}
