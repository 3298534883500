@import "../../../styles/variables";

%objective-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  align-items: center;
}

%objective-description {
  display: flex;
  flex-direction: column;
  width: 50%;

  .objective-number {
    font-weight: 800;
    font-size: 2.5rem;
    color: $main-color;
    padding-bottom: 1rem;
  }

  .objective-title {
    font-size: 1.3rem;
    font-weight: 600;
  }

  p {
    display: inline-block;
    margin-top: 1rem;
    line-height: 3rem;

    &:last-child {
      font-size: 1.2rem;
      line-height: 3rem;
    }
  }
}

.objectives-main-container {
  margin: 8rem auto 0rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90rem;
  width: 90%;

  .objectives-header {
    h2 {
      @include header-styles(2rem, 0rem, 1.5rem, 0.7rem, 1.5rem, center);
    }
  }

  .objectives-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-top: 3rem;

    .objective-container {
      @extend %objective-container;

      .objective-description {
        @extend %objective-description;
      }
    }
    .objective-container-reverse {
      @extend %objective-container;
      flex-direction: row-reverse;

      .objective-description {
        @extend %objective-description;
      }
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .objective-container,
  .objective-container-reverse {
    flex-direction: column-reverse !important;

    .objective-description {
      width: 100% !important;
      text-align: center;
    }
    .objective-image {
      img {
        max-width: 450px;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}


@media (max-width: 500px) {
  .objectives-main-container {
    .objectives-header {
      width: 100%;
      display: flex;
      justify-content: center;
      h2 {
        font-size: $small-screen-header-font-size !important;
        max-width: 13rem !important;
        width: 90%;
      }
    }
    .objective-container,
    .objective-container-reverse {
      gap: 1.5rem !important;
      .objective-description {
        h2 {
          font-size: 2.2rem !important;
        }

        h3 {
          font-size: 1.2rem !important;
          line-height: $small-screen-line-height !important;
          text-align: center;
        }

        p {
          font-size: $small-screen-description-font-size !important;
          line-height: $small-screen-line-height !important;
          text-align: center;
        }
      }
    }
  }
}
