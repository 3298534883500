@import "../../../styles/variables";

.skeleton-loading-main {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 8rem auto 0 auto;

  .skeletal-header {
    width: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
    height: 1.5rem;
    border-radius: 5px;
  }

  .skeletal-image {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
    width: 100%;

    .skeletal-image-skeleton {
      max-width: 600px;
      width: 100%;
      height: 400px;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  .skeletal-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    span {
      display: inline-block;
      max-width: 30rem;
      height: 1.3rem;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  .skeletal-details-description {
    margin-top: 2rem;
    border-top: 1px solid rgb(201, 201, 201);

    .skeletal-title {
      margin-top: 2rem;
      display: inline-block;
      max-width: 20rem;
      width: 100%;
      height: 1.3rem;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .skeletal-description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;

      span {
        display: inline-block;
        width: 100%;
        height: 1.2rem;
        border-radius: 5px;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
  }
}

.event-details-main-container {
  display: flex;
  flex-direction: column;
  margin: 8rem auto 0 auto;
  width: 90%;

  .event-details-header {
    width: 100%;
    text-align: center;
    font-size: 2.3rem;
    font-weight: 600;
  }

  .event-details-images {
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 27rem;
    padding: 2rem;
    width: 100%;
  }

  .event-details-image-slider-controls {
    margin: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .dot {
      width: 20px;
      height: 20px;
      background-color: rgb(231, 231, 231);
      border-radius: 100%;
    }
    .active {
      width: 25px;
      height: 25px;
      background-color: $main-color;
    }

    .left,
    .right {
      font-size: 1.2rem;
      font-weight: 900;
      background-color: white;
      border: 2px solid black;
      color: black;
      padding: 0.5rem 0.8rem;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }

  .event-details-info {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid gray;

    span {
      font-weight: 400;
    }

    .event-details-video {
      button {
        background-color: $main-color;
        color: white;
        padding: 0.2rem 1rem;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        gap: 0.5rem;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .event-details-info {
    .event-details-speaker {
      span {
        line-height: 2rem;
      }

      .event-details-speaker-name {
        &:hover {
          color: $main-color;
          cursor: pointer;
        }
      }
    }
    span {
      line-height: 2rem;
    }
  }

  .event-details-description {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.1rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid $main-color;
      padding: 0.5rem 2rem 0.5rem 0.5rem;
      width: fit-content;
      text-align: left;
      margin-bottom: 1rem;
    }

    p {
      line-height: 3rem;
      font-size: 1.1rem;
    }
  }
}

.event-details-video button {
  background-color: $main-color;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  gap: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-details-video button span {
  font-weight: 700 !important;
}

.event-details-video img {
  width: 15px;
  height: 15px;
}

.event-details-back-to-events-button {
  margin-top: 1rem;
}

.event-details-back-to-events-button a {
  text-decoration: none;
}

.event-details-back-to-events-button button {
  background-color: $main-color;
  color: white;
  padding: 0.6rem 1.7rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 0.8rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Responsiveness
@media (max-width: 1800px) {
  .event-details-images {
    margin: 3rem 0rem;
    width: 80% !important;
    img {
      width: 60% !important;
      height: auto;
      object-fit: contain !important;
    }
  }
  .event-details-image-slider-controls {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1300px) {
  .event-details-images {
    margin: 3rem 0rem;
    height: 25rem !important;
    img {
      width: 80% !important;
      object-fit: contain !important;
      // height: auto !important;
    }
  }
  .event-details-image-slider-controls {
    margin-bottom: 2rem;
  }
}

@media (max-width: 900px) {
  .event-details-images {
    height: 18rem !important;
  }
}

@media (max-width: 700px) {
  .event-details-images {
    height: 12rem !important;
  }
}

@media (max-width: 500px) {
  .event-details-images {
    margin: 0 auto !important;
    height: 18rem !important;

    img {
      width: 100% !important;
      // height: auto !important;
    }
  }
  .event-details-header {
    font-size: $phone-screen-main-header-font-size !important;
  }
  .event-details-description {
    align-items: center;

    h2 {
      font-size: 1.2rem;
      width: fit-content;
      padding: 0.5rem 2rem !important;
    }

    p {
      text-align: justify !important;
      line-height: $small-screen-line-height !important;
    }
  }

  .event-details-back-to-events-button {
    display: flex;
    justify-content: center;
  }
}
