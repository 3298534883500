@import "../../../styles/variables";

.who-we-are-main-container {
  margin-top: 5rem;
  display: flex;
  width: 80%;
  margin: 5rem auto 0rem auto;
  align-items: center;

  .who-we-are-description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      @include header-styles(2rem, 0rem, 0rem, 0.7rem, 0rem, left);
      width: 14rem;
    }
    p {
      @include description-styles(80%);
    }
  }
}

@media (max-width: 1700px) {
  .who-we-are-main-container {
    width: 85%;
  }
}

@media (max-width: 1500px) {
  .who-we-are-main-container {
    width: 90%;

    .who-we-are-description {
      width: 60%;
    }

    .who-we-are-image-container {
      img {
        width: 500px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1200px) {
  .who-we-are-main-container {
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    .who-we-are-description {
      width: 100%;
      align-items: center;

      h1 {
        @include header-styles(2rem, 0rem, 1rem, 0.7rem, 1rem, center);
        width: 17rem;
      }
      p {
        text-align: justify;
      }
    }
  }
}
@media (max-width: 520px) {
  .who-we-are-main-container {
    .who-we-are-image-container {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}
@media (max-width: 500px) {
  .who-we-are-description {
    h1 {
      display: inline-block;
      font-size: $small-screen-header-font-size !important;
      padding: 0rem 1.5rem 0.8rem 1.5rem !important;
      width: fit-content !important;
    }

    p {
      font-size: $small-screen-description-font-size !important;
      line-height: 2.5rem !important;
      width: 100% !important;
    }
  }
}
