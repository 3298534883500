@import "../../../styles/variables";

.about-us-description-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  h1 {
    @include header-styles(3rem, 0rem, 2.3rem, 0.7rem, 2.3rem, center);
  }

  p {
    @include description-styles(90%);
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .about-us-description-main-container {
    h1 {
      font-size: $tablet-screen-main-header-font-size;
    }
    p {
      text-align: justify;
    }
  }
}

@media (max-width: 500px) {
  .about-us-description-main-container {
    h1 {
      font-size: $phone-screen-main-header-font-size;
      width: fit-content;
    }
    p {
      font-size: $small-screen-description-font-size;
      line-height: $small-screen-line-height;
      text-align: justify;
    }
  }
}
