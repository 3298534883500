.show-notification-main {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 20;
  span {
    gap: 1rem;
    border-left: 10px solid green;
    box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.24);
    background-color: white;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    font-weight: 700;
    padding: 1rem 2rem;
  }
}
