@import "../../../styles/variables";
@import "../../../styles/works";

.lab-members-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  .lab-members-header {
    h1 {
      font-size: 3rem;
      font-weight: 600;
      padding: 0rem 2.3rem 0.7rem 2.3rem;
      border-bottom: 3px solid $main-color;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .lab-members-container {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto 0rem auto;
    width: 96%;

    .skeletal-loading-main-container {
      border: 1px solid $skeleton-loading-backgroung-color;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 21rem;
      width: 100%;
      align-items: center;
      padding: 1.5rem;

      .skeletal-dummy-image {
        max-width: 170px;
        width: 100%;
        height: 170px;
        border-radius: 170px;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeletal-dummy-name-and-position {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        border-bottom: 1px solid $skeleton-loading-backgroung-color;
        padding-bottom: 1rem;
        align-items: center;
        span {
          display: inline-block;
          height: 1rem;
          animation: skeleton-loading 1s linear infinite alternate;

          &:first-child {
            width: 80%;
          }
          &:last-child {
            width: 65%;
          }
        }
      }

      .skeletal-dummy-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        span {
          display: inline-block;
          width: 100%;
          height: 1rem;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
    }

    .lab-member-main {
      border: 1px solid black;
      width: 21rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s ease-in-out;
      line-height: 1.8rem;

      a {
        text-decoration: none;
        color: black;
      }

      &:hover {
        transform: scale(1.02);

        a {
          color: $main-color;
        }
      }

      .lab-member-profile {
        img {
          width: 170px;
          height: 170px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .lab-member-positions {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding-bottom: 0.7rem;
        border-bottom: 2px solid $main-color;

        span {
          text-align: center;

          &:first-child {
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 2rem;
          }

          &:last-child {
            font-size: 1.1rem;
            font-weight: 500;
            color: $gray-color;
          }
        }
      }

      .lab-member-about {
        margin-top: 0.7rem;
      }

      .lab-member-admin-buttons {
        flex-wrap: wrap;
        justify-content: center;
        button {
          background-color: $main-color;
          color: white;
          padding: 0.6rem 1.7rem;
          font-weight: 600;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          margin-top: 0.8rem;
          font-family: inherit;
          font-size: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .lab-members-header {
    h1 {
      font-size: $tablet-screen-main-header-font-size !important;
    }
  }
}
@media (max-width: 500px) {
  .lab-members-header {
    h1 {
      font-size: $phone-screen-main-header-font-size !important;
    }
  }
  .lab-members-container {
    .lab-member-profile {
      img {
        width: 140px !important;
        height: 140px !important;
        border-radius: 50%;
      }
    }
    .skeletal-loading-main-container {
      .skeletal-dummy-image {
        max-width: 120px !important;
        width: 100%;
        height: 120px !important;
        border-radius: 120px;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
  }
}
