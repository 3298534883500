@import "./variables";

.auto-logout-main-container {
  background: white;
  padding: 2.3rem 3rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  gap: 0.5rem;
  text-align: center;
  max-width: 20rem;
  width: 90%;

  span {
    font-size: 1.2rem;
    font-weight: 600;
  }

  button {
    @extend %button-styles;
  }
}
