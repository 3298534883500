@import "../../../styles/variables";
@import "../styles/AdminComponentStyles.scss";

.admin-navigation-main-container {
  position: relative;
  position: sticky;
  top: 4.4rem;
  display: flex;
  z-index: 10;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  background-color: white;
  padding: 0rem 2rem 0rem 1rem;

  .admin-buttons-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    justify-self: center;
    flex: 1;

    button {
      @extend %navigation-buttons;
    }
  }

  .logout-container {
    button {
      padding: 0.4rem 1.8rem;
      border: none;
      border-radius: 5px;
      background-color: white;
      color: $main-color;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &:hover {
        background-color: $main-color;
        color: white;

        img {
          filter: invert(1);
        }
      }
    }
    img {
      width: 30px;
      height: 30px;
      filter: invert(15%) sepia(72%) saturate(6213%) hue-rotate(343deg)
        brightness(83%) contrast(107%);
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .admin-navigation-main-container {
    top: 3.2rem;
  }
}

@media (max-width: 950px) {
  .admin-navigation-main-container {
    flex-direction: column;
  }
}
