@import "../../../styles/variables";

@mixin header-span($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}

%main-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
}

%authentication-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  span:first-child {
    @include header-span(1.4rem, 600);
  }

  span:nth-child(2) {
    @include header-span(1rem, 400);
  }
}

%authentication-form {
  max-width: 20rem;
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    input {
      border: 1px solid $border-color;
      background-color: #f8f8f8;
      border-radius: 5px;
      font-family: inherit;
      padding-left: 1rem;
      max-width: 30rem;
      width: 100%;
      height: 3rem;

      &:focus {
        border: none;
        outline: 2px solid $main-color;
        background-color: white;
      }
    }

    button {
      width: 70%;
      background-color: $main-color;
      color: white;
      padding: 0.8rem 4rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
