@import "../styles/authentication";

.admin-register-page-main {
  position: relative;
  @extend %main-page-content;

  .admin-register-header {
    @extend %authentication-header;
  }
  .admin-register-form {
    @extend %authentication-form;
  }
}
