@import "../../../styles/variables";

.our-partners-main-container {
  margin-top: 8rem;
  text-align: center;

  .our-partners-header {
    display: flex;
    justify-content: center;

    h2 {
      @include header-styles(2rem, 0rem, 1.2rem, 0.7rem, 1.2rem, center);
    }
  }

  .our-partners-images {
    margin: 2.5rem auto 0rem auto;
    width: 80%;
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;

    .skeletal-dummy-image {
      width: 300px;
      height: 200px;
      border-radius: 5px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .our-partners-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;

      img {
        width: 250px;
        height: auto;
        object-fit: cover;
      }

      .partners-buttons {
        display: flex;
        gap: 1rem;
        border-bottom: 1px solid black;
        padding-bottom: 1rem;
        width: 90%;
        justify-content: center;

        button {
          padding: 0.4rem 0.7rem;
          font-size: 0.8rem;
          background-color: $main-color;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .our-partners-main-container {
    .our-partners-images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;


      .our-partners-container {
        img {
          width: 90%;
          object-fit: contain;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .our-partners-main-container {
    .our-partners-header {
      h2 {
        display: inline-block;
        @include header-styles(
          $small-screen-header-font-size,
          0rem,
          1.2rem,
          0.7rem,
          1.2rem,
          center
        );
        max-width: 14rem !important;
        width: 90% !important;
      }
    }

    .our-partners-images {
      .skeletal-dummy-image {
        max-width: 300px;
        width: 100%;
      }
      .our-partners-container {
        img {
          width: 80%;
          object-fit: contain;
        }
      }
    }
  }
}
