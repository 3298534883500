@import "../styles/variables";

.footer-main-comtainer {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid rgb(87, 87, 87);
  width: 85%;
  margin: 8rem auto 0 auto;
  padding: 3rem 0rem 4rem 0rem;

  .footer-social-media {
    display: flex;
    gap: 2rem;

    img {
      width: 25px;
      height: 25px;
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: invert(16%) sepia(68%) saturate(5880%) hue-rotate(343deg)
          brightness(82%) contrast(108%);
        transform: scale(1.3);
      }
    }
  }
}

@media (max-width: 1280px) {
  .footer-main-comtainer {
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    .footer-description {
      text-align: center;
      line-height: 3rem;
    }
  }
}

@media (max-width: 500px) {
  .footer-main-comtainer {
    gap: 1.2rem;

    .footer-description {
      font-size: 0.8rem;
      line-height: 2rem;
    }

    .footer-logo{

      img{

        width: 100px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 320px) {
  .footer-social-media {
    flex-wrap: wrap;
    justify-content: center;
  }
}
