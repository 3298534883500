html {
  scroll-behavior: smooth;
}

.t-form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.t-form-select-field{
    width: 80%;
    margin: "auto"
}

.t-field-label{
  width: 80%;
  margin: "auto";
}

.t-form-error{
    width: 80%;
    color: red;
    font-size: 0.8rem;
    text-align: start;
}

.training-intro{
  width: 100%;
  height: 100vh;
  /* background-color: #d90429; */
  background: rgba(0,0,0,0.85);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* padding: 6rem 4rem; */
  gap: 4rem;
  /* overflow: auto; */
  position: relative;
}

.intro-background{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1
}

.intro-content{
  padding: 8rem;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}



@media only screen and (max-width: 600px) {
  .intro-content {
    padding: 1.5rem;
  }
}
