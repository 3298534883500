@import "../styles/variables";

%main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  %header {
    h1 {
      @include header-styles(3rem, 0rem, 2.3rem, 0.7rem, 2.3rem, center);
    }
  }

  %list-main-container {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto 0rem auto;
    width: 96%;

    a {
      text-decoration: none;
      color: black;
    }

    %list-item {
      border: 1px solid black;
      width: 21rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s ease-in-out;
      line-height: 1.8rem;

      img {
        max-width: 280px;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      %list-item-title {
        margin-top: 0.9rem;
        font-size: 1.1rem;
        font-weight: 600;
        border-bottom: 1px solid black;
        padding-bottom: 0.8rem;
      }

      &:hover {
        transform: scale(1.02);

        %list-item-title {
          color: $main-color;
        }
      }

      %list-item-description {
        margin-top: 0.5rem;
        line-height: 2rem;
      }

      %admin-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        button {
          background-color: $main-color;
          color: white;
          padding: 0.5rem 1.5rem;
          font-weight: 600;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          margin-top: 0.8rem;
          font-family: inherit;
          font-size: 0.9rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  %header {
    h1 {
      font-size: $tablet-screen-main-header-font-size;
    }
  }
}

@media (max-width: 500px) {
  %header {
    h1 {
      font-size: $phone-screen-main-header-font-size !important;
      text-align: center;
      width: fit-content;
    }
  }
}
