@import "../../../styles/variables";

.research-themes-main-container {
  margin: 8rem auto 0rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90rem;
  width: 90%;

  .research-themes-header {
    h2 {
      @include header-styles(2rem, 0rem, 1.5rem, 0.7rem, 1.5rem, center);
    }
  }

  .research-themes {
    margin-top: 3rem;

    .research-theme {
      margin-bottom: 2rem;
      display: flex;
      gap: 2rem;
      padding: 2rem;
      border-radius: 10px;
      border: 2px solid #bbbbbb;
      align-items: center;

      .research-theme-image {
        img {
          max-width: 450px;
          height: auto;
        }
      }

      .research-theme-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
          font-size: 1.3rem;
          font-weight: 600;
          line-height: 3rem;
        }
        p {
          font-size: 1.3rem;
          line-height: 3rem;
        }
      }
    }
  }
}

//Responsiveness
@media (max-width: 1200px) {
  .research-theme {
    flex-direction: column;
    gap: 4rem !important;

    .research-theme-image {
      img {
        width: 100%;
      }
    }

    .research-theme-description {
      gap: 1.2rem !important;

      h3 {
        text-align: center;
        line-height: 3rem;
      }
      p {
        text-align: center;
      }
    }
  }
}

@media (max-width: 500px) {
  .research-themes-main-container {
    .research-themes-header {
      display: flex;
      justify-content: center;
      width: 100%;
      h2 {
        font-size: $small-screen-header-font-size !important;
        max-width: 18rem;
        width: 90%;
        text-align: center;
      }
    }
    .research-theme {
      gap: 1.2rem !important;

      .research-theme-image {
        img {
          max-width: 500px;
          width: 100%;
          height: auto;
        }
      }
      .research-theme-description {
        h3 {
          font-size: 1.2rem !important;
          text-align: center;
        }
        p {
          font-size: $small-screen-description-font-size !important;
          line-height: $small-screen-line-height !important;
          text-align: center;
        }
      }
    }
  }
}
