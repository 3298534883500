@import "../../../styles/variables";

.our-mission-main-container {
  display: flex;
  width: 90%;
  margin: 6rem auto 0rem auto;
  padding: 2rem;

  .our-mission-info {
    display: flex;
    flex-direction: column;
    width: 60%;

    h2 {
      @include header-styles(2rem, 0rem, 2rem, 0.7rem, 0rem, left);
      width: 15rem;
    }

    p {
      @include description-styles(70%);
    }
  }

  .our-mission-video {
    video {
      max-width: 600px;
      height: auto;
      border-radius: 5px;
      outline: none;
    }
  }
}

@media (max-width: 1300px) {
  .our-mission-main-container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .our-mission-info {
      align-items: center;
      width: 100%;

      h2 {
        @include header-styles(2rem, 0rem, 2rem, 0.7rem, 2rem, center);
        max-width: 16rem;
        width: 100%;
      }

      p {
        @include description-styles(100%);
        text-align: center;
      }
    }

    .our-mission-video {
      width: 100%;
      padding-bottom: 0; /* Remove padding to display full video */
      border-radius: 5px;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 800px) {
  .our-mission-main-container {
    padding: 0rem;

    .our-mission-info {
      padding: 1rem;
    }
    .our-mission-video {
      video {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 500px) {
  .our-mission-main-container {
    padding: 0rem;
    margin-top: 4rem;

    .our-mission-info {
      padding: 1rem;

      h2 {
        font-size: $small-screen-header-font-size;
      }
      p {
        font-size: $small-screen-description-font-size;
        line-height: $small-screen-line-height;
        text-align: justify;
      }
    }
  }
}
